import React, { FunctionComponent } from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Pill from "../../components/Pill"
import Container from "../../components/Container"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import ActionPanel from "../../components/home/ActionPanel"
import SignUpForm from "../../components/SignUpForm"

interface QueryResult {
  mdx: {
    frontmatter: {
      title: string
      seo: {
        title: string
        description: string
      }
    }
    body: string
  }
}

interface Props {
  data: QueryResult
}

const HiringPage: FunctionComponent<Props> = ({ data }) => {
  const page = data.mdx
  return (
    <Layout>
      <Seo
        description={page.frontmatter.seo.description}
        pathname="/hiring"
        title={page.frontmatter.seo.title}
      />

      <header>
        <Pill bgClass="bg-green-100" variant="center-right">
          <Container>
            <div className="br-prose py-24 xxl:py-32">
              <div className="br-col-gap md:grid md:grid-cols-12">
                <div className="md:col-span-10 md:col-start-2 xxl:col-span-8 xxl:col-start-3">
                  <h1 className="mb-8 text-center">{page.frontmatter.title}</h1>
                </div>
              </div>
            </div>
          </Container>
        </Pill>
      </header>
      <section className="pt-24">
        <Container>
          <div className="br-col-gap md:grid md:grid-cols-12">
            <div className="md:col-span-10 md:col-start-2 xxl:col-span-8 xxl:col-start-3">
              <div className="br-prose mx-auto" style={{maxWidth: '80ch'}}>
                <MDXRenderer>{page.body}</MDXRenderer>
              </div>
            </div>
          </div>
          <div className="pt-24 pb-24">
            <ActionPanel>
              <h3 className="mb-6 text-4 md:mb-8 md:text-8">
                Join the Breezy team
              </h3>

              <p className="mb-10 md:mb-16">
                Want to help us shape the future of partner discovery? We’re always looking for motivated self-starters to join our expanding&nbsp;team.
              </p>

              <div className="max-w-xl mx-auto">
                <div className="text-center">
                  <a
                    className="bg-white cursor-pointer duration-500 font-bold inline-block px-20 py-4
                    rounded-full shadow-md text-4 text-blue hover:bg-black hover:shadow-lg"
                    href="mailto:hr@breezy.io"
                    title="Contact Us"
                  >
                    Get in touch
                  </a>
                </div>
              </div>
            </ActionPanel>
          </div>

        </Container>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query hiring {
    mdx(fileAbsolutePath: { regex: "//static/hiring-index/" }) {
      frontmatter {
        title
        seo {
          description
          title
        }
      }
      body
    }
  }
`

export default HiringPage
